<template>
    <div class="content-box">
      <!-- <div class="content-header">
        <el-input
          v-model="h5Url"
          clearable
          placeholder="请输入跳转链接（支持H5）"
          class="url-input"
        ></el-input>
        <el-button type="primary" :disabled="disabledSaveUrlBtn" :loading="saveLoading" @click="saveUrl">保存</el-button>
      </div> -->
      <div class="table-list">
        <el-tabs type="border-card" v-model="tabActivated">
          <!-- <el-tab-pane label="专业调理" :name="LungListTabEnum.Profession">
            <Profession :tableMaxheight="tableMaxheight" :tabActivated="tabActivated" />
          </el-tab-pane> -->
          <el-tab-pane label="居家保养" :name="LungListTabEnum.Home">
            <Home :tableMaxheight="tableMaxheight" :tabActivated="tabActivated" />
          </el-tab-pane>
        </el-tabs>
      </div>
      <!-- <div class="table-list">
        <el-table :data="tableData" style="width: 100%" :max-height="tableMaxheight" :header-cell-style="headerCellStyle" border
          v-loading="loading">
          <el-table-column prop="id" align="center" width="" label="序号">
          </el-table-column>
          <el-table-column prop="serviceProviderName" align="center" label="服务商名称">
          </el-table-column>
          <el-table-column prop="storeServiceOwners" align="center" label="负责人">
            <template #default="{row}">
              <p v-for="ssOwner in row.storeServiceOwners" :key="ssOwner.id">
                {{`${ssOwner.nickname}: ${ssOwner.phone ?? ""}`}}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="storeInfo" align="left" label="关联门店">
            <template #default="{row}">
              <p v-for="ssOwner in row.storeInfo" :key="ssOwner.id">
                {{ssOwner.storeName}}<span class="store-saas-name" v-if="ssOwner.storeSaaSId">(SaaS系统:{{ssOwner.storeSaaSName?.substring(0, 16)}})</span>
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="remark" align="center" label="备注">
          </el-table-column>
          <el-table-column label="操作" width="180" align="center">
            <template #default="{row}">
              <el-button :class="{ 
                'disable-btn': row.enableState == serviceStatusEnum?.Enable,
                'enable-btn': row.enableState == serviceStatusEnum?.Disable,
                }"
               type="text" @click="handleUpdateStatus(row)">{{ row.enableState == serviceStatusEnum?.Enable ? "启用" : "禁用"}}</el-button>
              <el-button type="text" @click="handleEdit(row)">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination style="margin-top: 20px" background @size-change="handleSizeChange"
          @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="pageSizes" :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="itemTotal"></el-pagination>
      </div>
      <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="460px" :before-close="dialogClose">
          <div class="edit-box">
            <el-form label-position="left" label-suffix=":" label-width="100px" :model="formData" :rules="formRules" ref="validateForm">
              <el-form-item label="服务商名称" prop="serviceProviderName">
                <el-input v-model="formData.serviceProviderName" class="dialogInput" placeholder="请输入服务商名称" maxlength="50">
                </el-input>
              </el-form-item>
              <el-form-item label="负责人">
                <el-select
                  clearable
                  :value="undefined"
                  @change="($event) => setCurUser($event, 'userInfo')"
                  class="dialogInput"
                  remote
                  :remote-method="getUserList"
                  @focus="getUserList"
                  filterable
                  placeholder="请输入负责人手机"
                  :loading="userSelectLoading"
                >
                  <el-option
                    v-for="item in allUserList"
                    :key="item.id"
                    :label="item.phone"
                    :value="item"
                  >
                  <span>{{ `${item.nickname ?? ""}${item.nickname ? ":" : ""} ${ item.phone ?? "" }` }}</span>
                  </el-option>
                </el-select>
                <el-tag
                  class="tag"
                  :key="tag.id"
                  v-for="tag in userInfo"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(tag, 'userInfo')"
                >
                  {{ `${tag.nickname}: ${tag.phone}` }}
                </el-tag>
              </el-form-item>
              <el-form-item label="关联门店" prop="storeInfo">
                <el-select
                  clearable
                  :value="undefined"
                  @change="($event) => setCurUser($event, 'storeInfo')"
                  class="dialogInput"
                  remote
                  :remote-method="getStoreList"
                  @focus="getStoreList"
                  @blur="validateField"
                  filterable
                  placeholder="请选择关联门店"
                  :loading="storeSelectLoading"
                >
                  <el-option
                    v-for="item in allStoreList"
                    :key="item.id"
                    :label="item.storeName"
                    :value="item"
                  >
                  <span>{{item.storeName}}</span> <span class="ellipsis-text store-saas-name" v-if="item.saasStoreId">SaaS系统:{{item.saasStoreName}}</span>
                  </el-option>
                </el-select>
                <el-tag
                  class="tag"
                  :key="tag.id"
                  v-for="tag in storeInfo"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(tag, 'storeInfo')"
                >
                  {{ tag.storeName }}<span class="ellipsis-text store-saas-name" v-if="tag.saasStoreId">(SaaS系统:{{tag.saasStoreName}})</span>
                </el-tag>
              </el-form-item>
              <el-form-item label="备注内容" prop="remark">
                <el-input v-model.number="formData.remark" class="dialogInput" type="textarea"
                  placeholder="请输入备注内容" maxlength="150"></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="dialogClose">取消</el-button>
              <el-button :loading="saveLoading" type="primary" @click="dialogConfirm">提交</el-button>
            </div>
          </div>
      </el-dialog> -->
    </div>
  </template>
  
<script>
import { Watch, Mixins, Component } from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";

import Home from "./component/home.vue";
import Profession from "./component/profession.vue";

import { getH5Url, updateH5Url } from "@/api/lung";

import { handleBackendDataWithDefaults } from "@/utils/common";

// 工具
import { debounce, cloneDeep, uniqWith } from "lodash";

// 常量
import { LungListTabEnum } from "@/enum/lung.enum";

@Component({
    components: {
        Home,
        Profession
    }
})
export default class LungList extends Mixins(loading, tablePagination, Resize) {

    LungListTabEnum = LungListTabEnum;

    oldH5Url = "";
    h5Url = "";
    saveLoading = false;

    tabActivated = LungListTabEnum.Home;

    tableData = [];
    itemTotal = 0;

    dialogVisible = false;
    dialogTitle = "";

    mounted() {
       this.windowResize(10)
       this.getH5Url();
        setTimeout(() => {
            this.hideLoading();
        }, 1000)
    }

    getH5Url() {
        getH5Url().then((res) => {
            const _data = handleBackendDataWithDefaults(res.data);
            console.log(_data)
            this.oldH5Url = _data;
            this.h5Url = _data;
        })
    }

    get disabledSaveUrlBtn() {
        return (this.oldH5Url == this.h5Url) || !this.h5Url;
    }

    saveUrl() {
        const params = { url: this.h5Url };
        this.saveLoading = true;
        updateH5Url(params).then((res) => {
          this.$message.success("设置成功")
          this.getH5Url();
        }).finally(() => {
          this.saveLoading = false;
        })
    }


    getData() {
        this.showLoading();
        getStoreServiceList({
            ...this.queryForm,
            pageNum: this.currentPage,
            pageSize: this.pageSize,
        }).then(res => {
            this.hideLoading();
            this.tableData = res.data.list;
            this.itemTotal = res.data.total;
        }).finally(() => {
            this.hideLoading();
        });
    }

    //重置分页参数
    resetPages() {
        this.currentPage = 1;
    }

}
</script>
  
  
  
<style lang="scss" scoped src="./style.scss"></style>@/enum/service.const